@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

body {
	padding-top: 70px;
}

.input-container {
	display: flex;
	flex-direction: column;
}

.green {
	color: green;
}

.red {
	color: red;
}

.pagination-dark .page-link {
	background-color: var(--bs-dark);
	color: var(--bs-light);
}

.pagination-dark .active .page-link {
	background-color: var(--bs-primary);
	color: var(--bs-light);
	border-color: var(--bs-primary);
}

.pagination-light .page-link {
	background-color: var(--bs-light);
	color: var(--bs-dark);
}

.pagination-light .active .page-link {
	background-color: var(--bs-primary);
	color: var(--bs-light);
	border-color: var(--bs-primary);
}

.no-break {
	word-wrap: break-word;
	overflow-wrap: break-word;
}

.login-view,
.signup-view {
	height: 100vh;
}

.favorite-btn-container {
	display: inline-block;
	width: auto;
	text-align: end;
}
.favorite-button {
	font-size: 2rem;
}

.form-select {
	min-width: 100px;
}
.moviecard {
	width: 260px;
	height: 500px;
}

.genrecard {
	width: 260px;
	height: 250px;
}

.actorcard {
	width: 260px;
	height: 500px;
}

.directorcard {
	width: 260px;
	height: 500px;
}

.container-min-vh-100 {
	min-height: 100vh;
}

.spin {
	display: inline-block;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.progress {
	position: relative;
	height: 4px;
	display: block;
	width: 100%;
	background-color: #ace7ef;
	border-radius: 2px;
	margin: 0.5rem 0 1rem 0;
	overflow: hidden;
}

.progress .indeterminate {
	background-color: #26a69a;
}

.progress .indeterminate:before {
	content: "";
	position: absolute;
	background-color: inherit;
	top: 0;
	left: 0;
	bottom: 0;
	will-change: left, right;
	animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress .indeterminate:after {
	content: "";
	position: absolute;
	background-color: inherit;
	top: 0;
	left: 0;
	bottom: 0;
	will-change: left, right;
	animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
		infinite;
	animation-delay: 1.15s;
}

@keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}

@keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}
